import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Flex, Box } from "rebass"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Flex alignItems="center" textAlign="center" flexDirection="column">
      <Box width={[1, 1 / 2, 1 / 3]} p={[2, 3, 4]}>
        <h1>You've found the secret chatroom!</h1>
      </Box>
      <Box width={[1, 1 / 2, 1 / 3]} p={[2, 3, 4]}>
        Nobody's here
        <br />
        <Link to="/">
          Go home{" "}
          <span role="img" aria-label="and cry" style={{ fontStyle: "normal" }}>
            &#x1F622;
          </span>
        </Link>
      </Box>
      <Box width={[1, 1 / 2, 1 / 3]} p={[2, 3, 4]}>
        <iframe
          title="chatroom"
          src="https://www6.cbox.ws/box/?boxid=845158&boxtag=autSyP"
          width="100%"
          height="400"
          allowtransparency="yes"
          allow="autoplay"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          scrolling="auto"
        ></iframe>
      </Box>
    </Flex>
  </Layout>
)

export default NotFoundPage
